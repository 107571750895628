import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './Profile.css';




const Profile = () => {


  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <>
      <div>
            <img className="pandaImage" src="panda_circle.png" alt="panda Image" /> 
      </div>
      <div className="container">
            <div className='text-box'>
              <h2>Welcome to the Protenus Hub</h2>
              <h3>Select a destination</h3>
            </div>
          {/* Help Center */}
          <a href="https://protenus1719603634.zendesk.com/auth/v2/login/continue_with_sso_tracking?auth_origin=30825480210067%2Cfalse%2Ctrue&brand_id=30825480210067&locale=en-us&remote_auth_id=31658403726867&return_to=https%3A%2F%2Fprotenus1719603634.zendesk.com%2Fhc%2Fen-us&role=end_user&theme=hc" target="_blank" rel="noopener noreferrer">
            <button className="linkButton">
              <div className="flex-box">
                <img src='helpIcon.png' className='icon' alt='helpdeskicon' />
                <h3>Help Center</h3>
              </div>
            </button>
          </a>
          <br />
          {/* Academy */}
          <a href="https://test-protenus.skilljar.com/auth/login/1g48b89wi8dx4" target="_blank" rel="noopener noreferrer">
            <button className="linkButton">
            <div className="flex-box">
                <img src='schoolIcon.png' className='icon' alt='helpdeskicon' />
                <h3>Protenus Academy</h3>
              </div>
            </button>
          </a>
          <br />
           {/* Habitat */}
          <a href="https://protenus-en-sandbox-community.insided.com/ssoproxy/login?ssoType=saml" target="_blank" rel="noopener noreferrer">
            <button className="linkButton">
              <div className="flex-box">
                <img src='habIcon.png' className='icon' alt='helpdeskicon' />
                <h3>PANDAS Habitat</h3>
              </div>
            </button>
          </a>
      </div>
    </>
    )
  );
};

export default Profile;
