import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './Navbar.css';
import LogoutButton from "./LogoutButton";
import { IoPersonCircleSharp } from "react-icons/io5";


const Navbar = () => { 
    const { user, isAuthenticated } = useAuth0();
        return (
            <div className="header">
                {isAuthenticated &&(
                    <div className="signout-container">
                        <div className="logoutButton"> 
                           <div className="sign-out-left">
                                <div className='user-name-box'>
                                <h2 className="user-name"> {user.name} </h2>
                                </div>
                                <div className='button1'>
                                < LogoutButton /> 
                                </div>
                            </div>
                            <div className="sign-out-right">
                                <IoPersonCircleSharp size="2.3em" className="usericon" /> 
                            </div>
                            
                        </div>
                    </div>
                )}
            </div>
        )
};
  
  export default Navbar;
